<template>
  <b-modal
    id="add-handle-distribution"
    centered
    hide-footer
    size="lg"
    title="Добавить ручное распределение"
  >
    <div class="m-2">
      <b-table
        id="order-table"
        :fields="fields"
        :items="payments"
        bordered
        empty-text="Нет данных"
        hover
        responsive
        show-empty
      >
        <template #cell(created)="row">
          {{ row.item.created.split('T')[0].split('-').reverse().join('.') }}
        </template>
        <template #cell(transaction_type)="row">
          <template v-if="editId === row.item.id">
            <b-form-select
              v-model="row.item.transaction_type"
              :options="transaction_types"
              placeholder="Тип транзакции"
              text-field="display_name"
              value-field="value"
            />
          </template>
          <template v-else
            >{{
              transaction_types.find(
                (item) => item.value === row.item.transaction_type
              ).display_name
            }}
          </template>
        </template>
        <template #cell(order)="row">
          <template v-if="editId === row.item.id">
            <b-form-input
              v-model="row.item.order"
              min="0"
              placeholder="Порядок"
              step="1"
              type="number"
            />
          </template>
          <template v-else>{{ row.item.order }}</template>
        </template>
        <template #cell(value)="row">
          <template v-if="editId === row.item.id">
            <b-form-input
              v-model="row.item.value"
              min="0"
              placeholder="Сумма"
              step="0.01"
              type="number"
            />
          </template>
          <template v-else>{{ row.item.value }}</template>
        </template>
        <template #cell(edit)="row">
          <b-button
            class="btn-icon"
            size="sm"
            variant="flat-success"
            @click="editId = row.item.id"
          >
            <b-icon-pencil />
          </b-button>
          <b-button
            class="btn-icon"
            size="sm"
            variant="flat-danger"
            @click="deleteOrder(row.item.id)"
          >
            <b-icon-trash />
          </b-button>
          <template v-if="editId === row.item.id">
            <b-button class="mt-1" size="sm" @click="editOrder(row.item)">
              Сохранить
            </b-button>
          </template>
        </template>
      </b-table>
      <b-form ref="form" @submit.prevent="addPayment">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Порядок" label-for="v-order">
              <b-form-input
                id="v-order"
                v-model="payment.order"
                min="0"
                placeholder="Порядок"
                step="1"
                type="number"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Тип транзакции" label-for="v-type">
              <b-form-select
                id="v-type"
                v-model="payment.transaction_type"
                :options="transaction_types"
                placeholder="Тип транзакции"
                text-field="display_name"
                value-field="value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Сумма" label-for="v-value">
              <b-form-input
                id="v-value"
                v-model="payment.value"
                min="0"
                placeholder="Сумма"
                step="0.01"
                type="number"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              type="submit"
              variant="primary"
            >
              Добавить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-overlay :show="show" no-wrap></b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AddHandleDistribution',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    id: { type: Number, required: true },
  },
  data() {
    return {
      show: false,
      payment: {
        payment: null,
        order: null,
        transaction_type: null,
        value: null,
      },
      editId: null,
      transaction_types: [],
      payments: [],
      fields: [
        {
          key: 'created',
          label: 'Дата платежа',
        },
        {
          key: 'transaction_type',
          label: 'Тип транзакции',
        },
        {
          key: 'order',
          label: 'Порядок',
        },
        {
          key: 'value',
          label: 'Сумма',
        },
        {
          key: 'edit',
          label: 'Редактирование',
        },
      ],
    }
  },
  watch: {
    id() {
      this.getPayments()
      this.payment = {}
    },
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    async addPayment() {
      this.payment.payment = this.id
      try {
        await this.$http.post('admin/payments/order/', this.payment)
        this.$toast.success('Платеж успешно добавлен')
        this.$refs.form.reset()
        await this.getPayments()
      } catch (error) {
        this.$toast.error('Что-то пошло не так')
        console.log(error)
      }
    },
    async getOptions() {
      const { data } = await this.$http.options('admin/payments/order/')
      this.transaction_types = data.actions.POST.transaction_type.choices
    },
    async getPayments() {
      const { data } = await this.$http.get('admin/payments/order/', {
        params: { payment: this.id },
      })
      this.payments = data.results
    },
    async deleteOrder(id) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить этот платеж?', {
          title: 'Подтвердите',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http.delete(`admin/payments/order/${id}/`)
            await this.getPayments()
          }
        })
    },
    async editOrder(order) {
      try {
        await this.$http.patch(`admin/payments/order/${order.id}`, order)
        this.$toast.success('Платеж успешно обновлен')
        this.editId = null
        await this.getPayments()
      } catch (error) {
        this.$toast.error('Что-то пошло не так')
        console.log(error)
      }
    },
  },
}
</script>

<style scoped></style>
