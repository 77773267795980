import { render, staticRenderFns } from "./AddHandleDistribution.vue?vue&type=template&id=830bdb8a&scoped=true&"
import script from "./AddHandleDistribution.vue?vue&type=script&lang=js&"
export * from "./AddHandleDistribution.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830bdb8a",
  null
  
)

export default component.exports