<template>
  <div class="m-2">
    <b-form @submit.prevent="addPayment">
      <b-row>
<!--        <b-col cols="12">-->
<!--          <b-form-group label="Договор" label-for="v-deal">-->
<!--            <b-form-select-->
<!--              id="v-deal"-->
<!--              v-model="payment.deal"-->
<!--              :options="deal_options"-->
<!--              placeholder="Договор"-->
<!--              text-field="document_number"-->
<!--              value-field="id"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->

        <b-col cols="12">
          <b-form-group label="Сумма" label-for="v-amount">
            <b-form-input
              id="v-value"
              v-model="payment.value"
              min="0"
              placeholder="Сумма"
              step="0.01"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Дата" label-for="v-date">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="payment.date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :initial-date="today"
              :max="today"
              locale="ru"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Тип платежа" label-for="v-type">
            <b-form-select
              id="v-source"
              v-model="payment.source"
              :options="source_options"
              placeholder="Тип платежа"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            type="submit"
            variant="primary"
          >
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-overlay :show="show" no-wrap></b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { number } from 'echarts/lib/export'

export default {
  name: 'DealAddPayment',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      show: false,

      today: new Date().toISOString().substring(0, 10),

      payment: {
        deal: null,
        value: null,
        date: new Date().toISOString().substring(0, 10),
        source: 2,
      },

      selectedStatus: 1,

      deal_options: [],

      source_options: [
        { text: 'Прямой платеж', value: 2 },
        { text: 'ЕРИП', value: 0 },
        { text: 'Иное', value: 4 },
      ],
    }
  },
  mounted() {
    // this.getDeals()
  },
  methods: {
    async getDeals() {
      const res = await this.$http.get(
        `admin/deals/?status=${this.selectedStatus}`
      )
      this.deal_options = res.data.results
    },

    async addPayment() {
      this.show = true
      this.payment.deal = this.$route.params.id
      try {
        await this.$http.post(
          `admin/deal/${this.payment.deal}/payment/create/`,
          this.payment
        )
        this.$toast.success('Платеж успешно добавлен и пересчитан')
      } catch (error) {
        this.$toast.error('Что-то пошло не так')
        console.log(error)
      }
      this.show = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
    },
  },
}
</script>
